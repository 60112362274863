import {
  Layers,
  File,
  UserPlus,
  Users
} from "react-feather";



export const AdminMenu = [
  {
    label: "Add Vendor",
    to: "/vendor-list",
    icon:<Users/>
  },
  {
    label: "Upload File",
    to: "/fileupload-list",
    icon: <File/>,
  },
]

export const vendorMenu = [
  {
    label: "Upload File",
    to: "/fileupload-list",
    icon: <File/>,
  },
]
export const sideMenu = [
  {
    label: "Add Vendor",
    to: "/vendor-list",
    icon:<Users/>
  },

  {
    label: "Upload File",
    to: "/fileupload-list",
    icon: <File/>,
  },
];