import React, { useState, useEffect } from "react";
import { Edit, Eye, MoreVertical, Trash, Download } from "react-feather";
import ReactPaginate from "react-paginate";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import VerifyDeleteModal from "../common/VerifyDeleteModal";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Modal,
  Input,
  Label,
  Row,
  Table,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import {
  useFileUploadListMutation,
  useDeleteFileUploadMutation,
} from "../../service";
import FileuploadView from "./FileuploadView";
import useDebounce from "../../hook/useDebounce";
import { getFileUpload } from "../../redux/fileUploadSlice";
import { useDispatch, useSelector } from "react-redux";
import ReactDatePicker from "react-datepicker";
function FileuploadList() {
  const navigate = useNavigate();
  const fileUploadListData = useSelector(
    (state) => state.fileUploadState.fileUploadList
  );
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [searchFields, setSearchFields] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [viewData, setViewData] = useState(null);
  const debounceSearch = useDebounce(searchFields,startDate,endDate, 500);
  const [reqfileUploadData, resfileUploadData] = useFileUploadListMutation();
  const [reqDelete, resDelete] = useDeleteFileUploadMutation();
  const [showModal, setShowModal] = useState(false);
  const [modalDetails, setModalDetails] = useState(null);
  const userInfo = useSelector((state) => state?.authState.userInfo);

  console.log("userInfo", userInfo);
  useEffect(() => {
    reqfileUploadData({
      page: currentPage,
      limit: 10,
      search: debounceSearch || "",
      start_date:(startDate && endDate) ? dayjs(startDate).format() : "",
      end_date:(startDate && endDate) ? dayjs(endDate).format() : "",
      email:userInfo?.email,
      role:userInfo?.role,
    });
  }, [currentPage, debounceSearch,startDate,endDate]);

  useEffect(() => {
    if (resfileUploadData?.isSuccess) {
      dispatch(getFileUpload(resfileUploadData?.data?.data?.docs));
      setPageCount(resfileUploadData?.data?.data?.totalPages);
    }
  }, [resfileUploadData]);

  // console.log("resfileUploadData", resfileUploadData.data);
  console.log("fileUploadList", fileUploadListData);

  useEffect(() => {
    if (resfileUploadData?.isError) {
      toast.error(
        resfileUploadData?.error?.data?.message
          ? resfileUploadData?.error?.data?.message
          : "Something Went Wrong",
        {
          position: "top-center",
          duration: 3000,
        }
      );
    }
  }, [resfileUploadData?.isError]);

  const onDownloadAction = (e, st) => {
    e.preventDefault();
    e.preventDefault(); // Prevent the default anchor behavior
    const filePath = st.file?.filepath; // Ensure file path is available

    if (filePath) {
      window.open(filePath, "_blank"); // Open the file in a new tab
    } else {
      console.error("File path not found");
    }
    // setViewData(st);
    // setModal(true);
  };

  const onEditAction = (e, cs) => {
    e.preventDefault();
    navigate("/fileupload-form", {
      state: {
        fileUploadId: cs?._id,
        isEdit: true,
      },
    });
  };

  const handleDeleteModal = (e, details) => {
    // console.log("details",details);
    e.preventDefault();

    setModalDetails({
      fileUploadname: details?.fname + " " + details.lname,
      id: details?._id,
    });
    setShowModal(true);
  };

  useEffect(() => {
    if (resDelete?.isSuccess) {
      toast.success(resDelete?.data?.message, {
        position: "top-center",
        duration: 3000,
      });
      reqfileUploadData({
        page: currentPage,
        limit: 10,
      });
      setShowModal(false);
      setModalDetails(null);
    }
  }, [resDelete]);

  const handleSearchField = (e) => {
    console.log("search value",e.target.value);
    setSearchFields(e.target.value);
    setCurrentPage(1);
  };

  const handleDateFilter = (tag, date) => {
    // console.log("taf and date",tag,date);
    if (tag === "start") {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  const handlePagination = (page) => {
    setCurrentPage(page?.selected + 1);
  };
  return (
    <>
      <div className="app-user-list">
        <Card className="overflow-hidden">
          <CardHeader>
            <CardTitle tag="h4">Customer List</CardTitle>
          </CardHeader>
          <CardBody>
            <Row className="align-items-end">
              <Col md="6" className="d-flex">
                <div className="w-50 me-1">
                  <Label for="status-select">Search</Label>
                  <Input
                    id="search-invoice"
                    type="text"
                    placeholder="By Customer name,email"
                    onChange={(e) => handleSearchField(e)}
                  />
                </div>
              </Col>
              <Col md="2" className="d-flex">
              <div className="w-50 me-1">
              <Label for="start Date">Search Date</Label>
                  <ReactDatePicker
                    selected={startDate}
                    onChange={(date) => handleDateFilter("start", date)}
                    placeholderText="Select From Date"
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                  />
                </div>
                </Col>
                <Col md="2" className="d-flex">
                <div className="w-50 me-1">
                <Label for="End Date">End Date</Label>
                  <ReactDatePicker
                    selected={endDate}
                    onChange={(date) => handleDateFilter("end", date)}
                    placeholderText="Select To Date"
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                  />
                </div>
              </Col>
              <Col md="2" className="d-flex">
                <div className="w-30 me-1">
                  <Button
                    color="primary"
                    onClick={(e) => navigate("/fileupload-form")}
                    className="mt-2"
                  >
                    Upload file
                  </Button>
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md="12">
                <Table>
                  <thead>
                    <tr>
                      <th>Customer Name</th>
                      <th>Customer Email</th>
                      <th>Date Uploaded</th>
                      <th>Uploaded File</th>
                      {userInfo.role === "Admin" && <th>Action</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(fileUploadListData) &&
                    fileUploadListData?.length > 0 ? (
                      fileUploadListData?.map((cs, i) => {
                        return (
                          <tr key={i}>
                            <td>{cs?.fname + " " + cs?.lname}</td>
                            <td>{cs?.email}</td>
                            <td>
                              {cs?.createdAt
                                ? dayjs(cs?.createdAt).format("DD/MM/YYYY")
                                : "-"}
                            </td>
                            <td>
                              {cs?.file.filename}{" "}
                              <Download
                                className="me-50"
                                size={15}
                                onClick={(e) => onDownloadAction(e, cs)}
                              />
                              {/* <span className="align-middle">
                                        Download
                                      </span> */}
                            </td>
                            {userInfo.role === "Admin" && (
                              <td>
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="icon-btn hide-arrow"
                                    color="transparent"
                                    size="sm"
                                    caret
                                  >
                                    <MoreVertical size={15} />
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    {/* <DropdownItem
                                      href="#!"
                                      onClick={(e) => onDownloadAction(e, cs)}
                                    >
                                      <Download className="me-50" size={15} />{" "}
                                      <span className="align-middle">
                                        Download
                                      </span>
                                    </DropdownItem> */}
                                    <DropdownItem
                                      href="#!"
                                      onClick={(e) => onEditAction(e, cs)}
                                    >
                                      <Edit className="me-50" size={15} />{" "}
                                      <span className="align-middle">
                                        Change Password
                                      </span>
                                    </DropdownItem>
                                    <DropdownItem
                                      href="#!"
                                      onClick={(e) => handleDeleteModal(e, cs)}
                                    >
                                      <Trash className="me-50" size={15} />{" "}
                                      <span className="align-middle">
                                        Delete
                                      </span>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            )}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="text-center" colSpan={7}>
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </CardBody>
          <ReactPaginate
            breakLabel={"..."}
            previousLabel={""}
            nextLabel={""}
            pageCount={pageCount}
            forcePage={currentPage !== 0 ? currentPage - 1 : 0}
            activeClassName="active"
            onPageChange={(page) => handlePagination(page)}
            pageClassName={"page-item"}
            nextLinkClassName={"page-link"}
            nextClassName={"page-item next"}
            previousClassName={"page-item prev"}
            previousLinkClassName={"page-link"}
            pageLinkClassName={"page-link"}
            containerClassName={`pagination ${
              pageCount > 0 ? "" : "hidden"
            } react-paginate justify-content-end my-2 pe-1`}
          />
        </Card>
      </div>
      <FileuploadView modal={modal} setModal={setModal} viewData={viewData} />
      <VerifyDeleteModal
        showModal={showModal}
        setShowModal={setShowModal}
        modalDetails={modalDetails}
        confirmAction={reqDelete}
      />
    </>
  );
}

export default FileuploadList;
