import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setFileUploadDropdown,setFileUploadEdit } from "../../redux/fileUploadSlice";
import { useCreateFileUploadMutation,useFileUploadByIdQuery,useFileUploadMutation } from "../../service";

function FileuploadForm() {
    const navigate = useNavigate();
    const location = useLocation();
    const { state: locationState } = location;
    const dispatch = useDispatch(); 
    const [reqCreateFileUpload, resCreateFileUpload] = useCreateFileUploadMutation();
    const [reqFile] = useFileUploadMutation();
    const editData = useSelector((state) => state.fileUploadState.setFileUploadEdit);
    console.log("edit data",editData);
   console.log("locationstate",locationState);
    const {
      control,
      handleSubmit,
      formState: { errors },
      reset,
      setValue,
      setError,
    } = useForm();
  
    const resfileUploadById = useFileUploadByIdQuery(locationState?.fileUploadId, {
      skip: !locationState?.fileUploadId,
    });
  
    console.log("resfileUploadById",resfileUploadById);
  
    useEffect(() => {
      if (resfileUploadById?.isSuccess && resfileUploadById?.data?.data) {
        const childTestId = resfileUploadById?.data?.data?.childTestId?.map(el => ({
          label: el?.name,
          value: el?._id
        }));
        // const services = resfileUploadById?.data?.data?.service.map(item => item)
        reset({
          ...resfileUploadById?.data?.data,
          fileUploadstatus: {
            label: resfileUploadById?.data?.data?.fileUploadstatus === "Inactive"?"Inactive" : "active",
            value: resfileUploadById?.data?.data?.fileUploadstatus === "Inactive"?"Inactive" : "active",
          },
          service:resfileUploadById?.data?.data?.service?.map((item) => ({
            label: item,
            value: item,
          })),
          childTestId,
        });
        dispatch(setFileUploadEdit({
          ...resfileUploadById.data.data,
          fileUploadstatus: {
            label: resfileUploadById?.data?.data?.fileUploadstatus === "Inactive"?"Inactive" : "active",
            value: resfileUploadById?.data?.data?.fileUploadstatus === "Inactive"?"Inactive" : "active",
          },
          service: resfileUploadById?.data?.data?.service?.map((item) => ({
            label: item,
            value: item,
          })),
          childTestId,
        }))
      }
    },[resfileUploadById]);
  
    useEffect(() => {
      if (resCreateFileUpload?.isSuccess) {
        console.log("resCreateFileUpload",resCreateFileUpload?.data?.message)
        toast.success(resCreateFileUpload?.data?.message, {
          position: "top-center",
          duration: 3000,
        });
        navigate("/fileupload-list");
      }
    }, [resCreateFileUpload?.isSuccess]);
  
    const handleCategoryChange = async (selectedOption) => {
    };
  
    const onSubmit = async (state) => {
      console.log("state Data", state);
  
      const payload = {
        ...state,
        file: state?.file?._id,
      };
    console.log("payload",payload);
      reqCreateFileUpload(payload);

    };

    const handleFile = (fileVal, name) => {
      const formData = new FormData();
      console.log("file val",fileVal);
      formData.append("file", fileVal.target.files[0]);
      const reqData = {
        file: formData,
        type: 99,
      };
      reqFile(reqData)
        .then((res) => {
          if (res?.data?.data) {
            const parts = res?.data?.data?.originalname.split(".");
            const fileNameWithoutExtension = parts.slice(0, -1).join(".");
            setValue("alt", fileNameWithoutExtension);
            setValue(name, res?.data?.data);
            setError(name, "");
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    };


  return (
    <div>
    <Card>
      <CardHeader>
      <CardTitle tag="h4"> {locationState?.isEdit ? " Edit File" : "Add File"}</CardTitle>
      </CardHeader>
      <CardBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md="6" className="mb-1">
              <Label className="form-label" for="fname">
                First Name
              </Label>
              <Controller
                id="fname"
                name="fname"
                control={control}
                rules={{ required: "fileUpload name is required" }}
                render={({ field }) => (
                  <Input type="text" placeholder="First name" {...field}
                name="fname"
                 />
                )}
              />
              {errors?.fname && (
                <FormFeedback>{errors?.fname?.message}</FormFeedback>
              )}
            </Col>
            <Col md="6" className="mb-1">
              <Label className="form-label" for="lname">
                Last Name
              </Label>
              <Controller
                id="lname"
                name="lname"
                control={control}
                rules={{ required: "last name is required" }}
                render={({ field }) => (
                  <Input type="text" placeholder="Last name" {...field}
                name="lname"
                 />
                )}
              />
              {errors?.lname && (
                <FormFeedback>{errors?.lname?.message}</FormFeedback>
              )}
            </Col>
            <Col md="6" className="mb-1">
                <Label className="form-label" for="email">
                  Email
                </Label>
                <Controller
                  id="email"
                  name="email"
                  control={control}
                  rules={{ required: "Email is required" }}
                  render={({ field }) => (
                    <Input
                      type="email"
                      placeholder="Email"
                      {...field}
                      name="email"
                    />
                  )}
                />
                {errors?.email && (
                  <FormFeedback>{errors?.email?.message}</FormFeedback>
                )}
              </Col>
              <Col
                md={editData?.file?.filepath ? "11" : "12"}
                className="mb-1"
              >
                <Label for="file">Upload File</Label>
                <Controller
                  id="file"
                  name="file"
                  control={control}
                  rules={{ required: "file is required" }}
                  render={({ field }) => (
                    <>
                      <Input
                        type="file"
                        placeholder="file"
                        onChange={(e) => {
                          field.onChange();
                          handleFile(e, "file");
                        }}
                        accept=".pdf, .jpg, .jpeg, .png"
                      />
                    </>
                  )}
                />
                {errors.file && (
                  <FormFeedback>{errors?.file?.message}</FormFeedback>
                )}
              </Col>
              {editData?.file?.filepath && (
                <Col md="1" className="mb-1">
                  <a
                    href={editData.file.filepath}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={editData.file.filepath}
                      height={50}
                      width={50}
                      style={{ marginTop: "1rem" }}
                      alt=""
                    />
                  </a>
                </Col>
              )}
              </Row>
          <Row>
            <Col md="12" className="d-flex justify-content-end">
              <Button
                type="reset"
                color="secondary"
                className="me-1"
                onClick={() => navigate("/fileupload-list")}
                outline
              >
                Back
              </Button>
              <Button
                type="reset"
                color="danger"
                className="me-1"
                onClick={() => reset()}
                outline
              >
                Reset
              </Button>
              <Button type="submit" color="primary">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  </div>
  )
}

export default FileuploadForm
