import { createSlice } from "@reduxjs/toolkit";

const initState = {
    fileUploadList:[],
    fileUploadView:null,
    fileUploadEdit:null,
    fileUploadAll:[],
    fileUploadDropdown: [],
};

export const fileUploadSlice = createSlice({
      name : "fileupload",
      initialState: initState,
      reducers: {
        getFileUpload: (state,{payload}) => {
            state.fileUploadList = payload;
        },
        setFileUploadView: (state,{payload}) => {
            state.fileUploadView = payload;
        },
        setFileUploadEdit: (state,{payload}) => {
            state.fileUploadEdit = payload;
        },
        setFileUpload: (state,{payload}) => {
            state.fileUploadAll = payload;
        },
        setFileUploadDropdown:(state,{payload}) => {
            state.fileUploadDropdown = payload;
        },
      }
})

export const {
    getFileUpload,
    setFileUploadView,
    setFileUploadEdit,
    setFileUpload,
    setFileUploadDropdown
} = fileUploadSlice.actions;

export default fileUploadSlice.reducer;